body,
html {
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: linear-gradient(to right, #515874 0%, #363945 100%) !important; */
}
body .web3modal-modal-card{
  border-radius: 8px !important;
}
body .web3modal-provider-wrapper:first-child{
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}
body .web3modal-provider-wrapper:nth-child(2){
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
body .web3modal-provider-container{
  border-radius: 8px !important;
}
#root {
  height: 100%;
  overflow: auto;
}